/* Icons */
.icon {
  display: inline-block;
  cursor: pointer;
  color: var(--clr-primary);
}
.icon.active {
  color: var(--clr-highlight);
}
.icon:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
