.filter {
  z-index: 10;
  margin-bottom: 10px;
  border-radius: 4px;
  background: var(--filter-bg);
  animation: dropdown-animate 0.3s both;
}
.filter-div:focus-within {
  border-color: var(--clr-border-input-focus);
  outline: 0;
  box-shadow: var(--box-shadow-input-focus);
}
.filter-div {
  border-radius: 4px;
}
.filter-input {
  max-width: 20rem;
  background-color: transparent;
}
.filter-input:focus,
.filter select {
  background-color: transparent;
  outline: 0;
}
.filter select[name="statusFilter"],
.filter select[name="eventFilter"] {
  min-width: 12rem;
  background-color: transparent;
}
@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
