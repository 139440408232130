/* Card */
.card {
  background-color: var(--clr-light);
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}

.card-body {
  padding: 0 20px 20px 20px;
}

/* react-calendar and date-picker*/
.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: var(--clr-light);
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
  padding: 0 20px 20px 20px;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation__label {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: var(--clr-calendar-btn-disabled);
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--clr-neutral);
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__weekdays__weekday > abbr {
  text-decoration: none;
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: 800;
}

.react-calendar__month-view__days__day > abbr {
  display: grid;
  place-items: center;
  height: 35px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  font-weight: 600;
}

.react-calendar__tile:disabled {
  color: var(--clr-neutral);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--clr-neutral);
}

.react-calendar__month-view__days__day:enabled:hover,
.react-calendar__month-view__days__day:enabled:focus {
  background-color: var(--clr-light);
}

.react-calendar__month-view__days__day:enabled > abbr:hover,
.react-calendar__month-view__days__day:enabled > abbr:focus {
  background-color: var(--clr-neutral);
}

.react-calendar__tile--active:enabled > abbr:hover,
.react-calendar__tile--active:enabled > abbr:focus {
  background: var(--clr-highlight);
  color: var(--clr-light);
}
.react-calendar__month-view .react-calendar__tile--active > abbr {
  background: var(--clr-light);
  border: 1px solid var(--clr-highlight);
  color: var(--clr-highlight);
}
.react-calendar__tile--hasActive {
  background: var(--clr-highlight);
  color: var(--clr-light);
}
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--hasActive:enabled:hover {
  background: var(--clr-highlight);
  color: var(--clr-light);
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: var(--clr-neutral-2);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--clr-neutral);
}

.date-picker .react-date-picker__wrapper {
  border: 0;
}

.date-picker {
  position: relative;
  width: 100%;
  padding: 0.13rem 0.75rem;
  border: 0.8px solid var(--clr-border-primary);
  border-radius: 6px;
}
.date-picker:focus-within {
  border-color: var(--clr-border-input-focus);
  outline: 0;
  box-shadow: var(--box-shadow-input-focus);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.date-picker .react-date-picker__calendar {
  right: 0 !important;
  left: auto !important;
  top: 42px !important;
  width: 450px;
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.primary-button {
  background-color: var(--btn-primary-bg);
}

.secondary-button {
  background-color: var(--btn-secondary-bg);
}
