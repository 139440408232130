/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
#main {
  margin-top: 60px;
  padding: 0px 30px;
  transition: all 0.3s;
}

@media (max-width: 1199px) {
  #main {
    padding: 0px 20px;
  }
}

.new-message {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 60px);
}
