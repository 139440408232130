.message-container {
  padding: 0.75rem;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--clr-light);
  max-width: 50%;
  border-radius: 7px;
  word-wrap: break-word;
  position: relative;
}

.tail-in::after {
  content: "";
  position: absolute;
  top: 0;
  left: -7px; /* Adjust the position of the tail */
  border-width: 7px;
  border-style: solid;
  border-color: var(--clr-light) var(--clr-light) transparent transparent;
}

.message-right {
  align-self: flex-end;
}

.tail-out::after {
  content: "";
  position: absolute;
  top: 0;
  right: -7px; /* Adjust the position of the tail */
  border-width: 7px;
  border-style: solid;
  border-color: var(--clr-light) transparent transparent var(--clr-light);
}
