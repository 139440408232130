/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 80;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--navbar-bg);
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

@media (min-width: 1200px) {
  #main,
  #footer {
    margin-left: 300px;
  }
  .public-navbar{
    display: none;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {
  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: var(--navbar-highlight-clr);
  transition: 0.3;
  background: var(--navbar-highlight-bg);
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  color: var(--navbar-highlight-clr);
}

.sidebar-nav .nav-link.collapsed {
  color: var(--navbar-clr);
  background: var(--navbar-bg);
}

.sidebar-nav .nav-link.collapsed i {
  color: var(--navbar-clr);
}

.sidebar-nav .nav-link:hover {
  color: var(--navbar-highlight-clr);
  background: var(--navbar-highlight-bg);
}

.sidebar-nav .nav-link:hover i {
  color: var(--navbar-highlight-clr);
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link.active .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--navbar-clr);
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: var(--navbar-highlight-bg);
}

.sidebar-nav .nav-content a.active i {
  background-color: var(--navbar-highlight-bg);
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}
