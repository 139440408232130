/*--------------------------------------------------------------
# Main
--------------------------------------------------------------*/
.configure-main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

@media (max-width: 1199px) {
  .configure-main {
    padding: 20px;
  }
}

/* Card */
.configure-main .card {
  margin-bottom: 30px;
  border: none;
  border-radius: 1.25rem;
  box-shadow: var(--box-shadow-primary);
}

.configure-main .card-title {
  text-align: center;
  padding: 0;
  font-size: 2rem;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}
.configure-main .info-text {
  text-align: center;
  padding-block: 0.75rem;
  color: var(--clr-primary);
  font-weight: 600;
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.configure-button {
  background-color: var(--clr-highlight);
  width: 100%;
  border-radius: 0.25rem;
}
