/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
  --clr-primary: #012970;  /* color of page title and  icon*/
  --clr-secondary: #f2f4f5; /* background of main element */
  --clr-light: #ffffff;  /* background of cards, header and navbar */
  --clr-highlight: #3a514a; /* highlight color for dashboard card icons and active tab of navbar */
  --clr-highlight-2: #b2caf5; /* highlights the icon of nav item in navbar and send button in announcement and chat page */
  --clr-text-primary: #444444;
  --clr-neutral: #e6e6e6; /* used in tile background of calendar and border */
  --clr-neutral-2: #757575; /* used in tile background of calendar */
  --clr-calendar-btn-disabled: #f0f0f0;

  /* background */
  --home-primary-bg: #3a514a;
  --home-secondary-bg: #fcf54f;
  --home-tertiary-bg: #f2f4f5;
  --modal-bg: rgba(0, 0, 0, 0.5);
  --filter-bg: #e7e8eb;
  --btn-primary-bg: #3a514a;
  --btn-secondary-bg: #6e757c;
  --btn-danger-bg: #d92e2e;

  /* header */
  --header-bg: #3a514a;
  --header-clr: #ffffff;

  /* navbar */
  --navbar-bg: #3a514a;
  --navbar-clr: #ffffff;
  --navbar-highlight-bg: #fcf54f;
  --navbar-highlight-clr: #012970;

  /* scrollbar color */
  --clr-scrollbar: #aab7cf;

  /* border color */
  --clr-border-primary: #dfe6de;
  --clr-border-input-focus: #35a211;

  /* alert color */
  --clr-success-alert-bg: #d1e7dd;
  --clr-success-alert-text: #0a3622;
  --clr-error-alert-bg: #f8d7da;
  --clr-error-alert-text: #58151c;

  /* box-shadow */
  --box-shadow-primary: 0px 0 30px rgba(0, 11, 4, 0.2);
  --box-shadow-input-focus: 0 0 0 0.25rem rgba(21, 57, 5, 0.4);
  --box-shadow-button-active: 0 5px #666;
}

body,
#root,
.App,
#main {
  font-family: "Open Sans", sans-serif;
  background: var(--clr-secondary);
  color: var(--clr-text-primary);
  min-height: calc(100vh - 60px);
}

a {
  color: var(--clr-highlight);
  text-decoration: none;
}

a:hover {
  color: var(--clr-highlight-2);
  text-decoration: none;
}

button {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
li {
  margin: 0;
  padding: 0;
}

/* To remove arrows of input[] */
/* Chrome, Safari, Edge, Opera  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*--------------------------------------------------------------
# Override some default Bootstrap stylings
--------------------------------------------------------------*/

/* Light Backgrounds */
.bg-primary-light {
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.bg-secondary-light {
  background-color: #e2e3e5;
  border-color: #e2e3e5;
}

.bg-success-light {
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.bg-danger-light {
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.bg-warning-light {
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.bg-info-light {
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.bg-dark-light {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
}

.form-control:focus {
  box-shadow: var(--box-shadow-input-focus);
  border-color: var(--clr-border-input-focus);
}
.form-check-input:checked{
  background-color: var(--home-primary-bg);
  border-color: var(--home-primary-bg);
}
