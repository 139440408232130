.home-container * {
  color: var(--clr-primary);
  font-family: Roboto, "Times New Roman", Times, serif;
  font-size: 1rem;
}

@media (min-width: 768px) {
  .home-container * {
    font-size: 1.25rem;
  }
}

#home-button {
  background-color: var(--home-primary-bg);
  color: var(--clr-light);
  font-family: "Nunito", sans-serif;
}

.hero-banner-1 {
  background-color: var(--home-secondary-bg);
}
.hero-banner-2 {
  background-color: var(--clr-light);
}
.hero-banner-3 {
  background-color: var(--home-tertiary-bg);
}

.text-justify {
  text-align: justify;
}
