/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/

.header {
  transition: all 0.5s;
  z-index: 100;
  height: 60px;
  box-shadow: var(--box-shadow-primary);
  background-color: var(--header-bg);
  padding-left: 20px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: var(--header-clr);
}
.header a {
  color: var(--clr-light);
}
.header a.active {
  border-bottom: 1px solid var(--clr-light);
}
.header #try-now-btn {
  background-color: var(--clr-light);
  color: var(--home-primary-bg);
}
.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: var(--clr-light);
}
.header .toggle-sidebar-btn {
  display: none;
}
.header .toggle-sidebar-btn:active{
  color: var(--clr-light);
  border-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}
@media (max-width: 1200px) {
  .header .toggle-sidebar-btn {
    display: block;
  }
  .public-nav {
    display: none !important;
  }
}
.toggle-sidebar-btn.always-visible {
  display: block;
  margin-left: 0 !important;
}
