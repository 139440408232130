.message-modal {
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
}

.modal-buttons {
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: space-evenly;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}
