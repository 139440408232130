.filter-dropdown {
  min-width: 12rem;
  text-align: left;
  white-space: nowrap;
}

/* Card */
.card {
  background-color: var(--clr-light);
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.cursor-pointer {
  cursor: pointer;
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.primary-button {
  background-color: var(--btn-primary-bg);
}

.secondary-button {
  background-color: var(--btn-secondary-bg);
}

.tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
}

.tabs li {
  flex-grow: 1;
  text-align: center;
  padding: 0.75rem 1rem;
  cursor: pointer;
  border-radius: 0.5rem 0.5rem 0 0;
  border: 0.8px solid var(--clr-border-primary);
}

.tabs li:hover {
  background-color: var(--clr-neutral);
}

.tabs li.active {
  border-bottom-width: 0;
}
.tabs li.inactive {
  border-width: 0 0 0.8px 0;
  
}

.tab-form {
  border: 0.8px solid var(--clr-border-primary);
  border-width: 0 0.8px 0.8px;
  border-radius: 0 0 0.5rem 0.5rem;
  align-items: center;
}
