.message-attach {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 1rem 0;
}
.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}
.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}

/* Table */
.table th,
.table td {
  font-family: "Poppins", sans-serif;
  background: transparent;
}

/* Card */
.card {
  background-color: var(--clr-light);
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
}

.cursor-pointer {
  cursor: pointer;
}
