.line-with-text {
  display: flex;
  flex-direction: row;
  margin-block: 1.5rem;
  width: 100%;
}
.line-with-text:before,
.line-with-text:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid var(--clr-border-primary);
  margin: auto;
}
.line-with-text:before {
  margin-right: 1rem;
}
.line-with-text:after {
  margin-left: 1rem;
}
