.pageTitle .dropdown-list {
  background-color: var(--clr-light);
  border-radius: 4px;
  padding: 0px;
  animation: dropdown-animate 0.3s both;
  border: 0;
  box-shadow: var(--box-shadow-primary);;
  position: absolute;
  right: 0;
  width: 170px;
  max-height: 200px;
  overflow: auto;
  z-index: 20;
}

.pageTitle .dropdown-list .dropdown-unit {
  background-color: var(--clr-light);
  color: var(--clr-text-primary);
  width: 100%;
  border: 1px solid var(--clr-neutral);
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
}

.pageTitle .dropdown-list .dropdown-unit i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0;
}

.pageTitle .dropdown-list .dropdown-unit:not([disabled]):hover {
  background-color: var(--clr-secondary);
}
.pageTitle .dropdown-list .dropdown-unit[disabled] {
  opacity: 50%;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
