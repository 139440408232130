.events-dropdown {
  width: 100%;
  position: absolute;
  z-index: 10;
  background-color: var(--clr-light);
  max-height: 20rem;
  overflow-y: auto;
  box-shadow: var(--box-shadow-primary);
}

.events-table {
  animation: dropdown-animate 0.3s ease-in;
  margin: 0;
}

.events-filter {
  margin-bottom: 10px;
  border-radius: 4px;
  background: var(--filter-bg);
  padding: 4px;
}

.events-filter-input,
.events-filter-input:focus {
  background-color: var(--filter-bg);
}
.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}

/* Table */
.table th,
.table td {
  font-family: "Poppins", sans-serif;
  background: transparent;
}

.events-table tr.active {
  background-color: var(--clr-highlight);
  color: var(--clr-light)
}
.events-table tr.active td {
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
