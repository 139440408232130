/* Card */
.card {
  background-color: var(--clr-light);
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-primary);
  font-family: "Poppins", sans-serif;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-icon {
  font-size: 32px;
  line-height: 0;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  flex-grow: 0;
  color: var(--clr-highlight);
  background: var(--clr-secondary);
}
.card-text {
  font-size: 20px;
  font-weight: bold;
  color: var(--clr-primary);
  padding-inline: 15px;
}
