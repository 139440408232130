.heading-container {
  position: sticky;
  top: 60px;
  background-color: var(--home-tertiary-bg);
  z-index: 20;
}
/*--------------------------------------------------------------
# Page Title
--------------------------------------------------------------*/
.pageTitle {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.pageTitle h1,
.pageTitle h2 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  color: var(--clr-primary);
}

hr {
  height: 2px;
  margin-block: 0.5rem;
  background-color: var(--clr-border-primary);
}
