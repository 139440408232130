.internet-error-container {
  position: fixed;
  z-index: 110;
  bottom: 0;
  font-size: 1.25rem;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--clr-error-alert-bg);
  color: var(--clr-error-alert-text);
}
