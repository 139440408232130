/* Table */
.table th,
.table td {
  font-family: "Poppins", sans-serif;
  background: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.primary-button {
  background-color: var(--btn-primary-bg);
}

.secondary-button {
  background-color: var(--btn-secondary-bg);
}
