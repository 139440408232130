.event-announcement-message {
  background-color: var(--clr-light);
  border-radius: 0.5rem;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.event-announcement-message:focus-within {
  border-color: var(--clr-border-input-focus);
  outline: 0;
  box-shadow: var(--box-shadow-input-focus);
}
.event-announcement-message input {
  border: 0;
  border-radius: 0.5rem;
}
.event-announcement-message input:focus {
  outline: 0;
}
.attach-btn {
  background-color: transparent;
}
.file-upload-options {
  background-color: var(--clr-light);
  box-shadow: var(--box-shadow-primary);
  position: absolute;
  padding: 1rem;
  z-index: 10;
  bottom: 45px;
  right: 0;
  max-width: 19.1rem;
  display: flex;
  gap: 2rem;
  flex-flow: row wrap;
}
.file-input-icon {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.label-text {
  color: var(--clr-text-primary);
}
.send-btn {
  border-radius: 50%;
  background-color: var(--clr-highlight-2);
  height: 2.5rem;
  width: 2.5rem;
  color: var(--clr-primary);
  box-shadow: var(--box-shadow-primary);
}

.cursor-pointer {
  cursor: pointer;
}
