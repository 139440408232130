.side-list {
  width: 15%;
  min-width: 12rem;
  max-width: 15%;
  overflow-y: auto;
}
.chat-nav-heading {
  font-size: 15px;
  font-weight: 600;
  color: var(--clr-highlight);
  background: var(--clr-secondary);
  padding: 10px 20px;
  margin: 20px 0 10px 0;
  border-radius: 4px;
}
.chat-nav-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--clr-primary);
  padding: 10px 0 10px 30px;
  cursor: pointer;
}
.chat-nav-item.active {
  background-color: var(--home-secondary-bg);
}
.chat-nav-item i {
  font-size: 6px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}
.chat-nav-item:hover {
  background-color: var(--home-secondary-bg);
}
