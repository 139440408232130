.message-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  height: 100vh;
  width: 100vw;
  background-color: var(--modal-bg);
  display: grid;
  place-items: center;
}
.message-preview-heading {
  color: var(--clr-primary);
}
.message-preview-content {
  max-height: 12rem;
  overflow-y: auto;
}

.thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--clr-scrollbar) transparent;
}
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--clr-light);
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--clr-scrollbar);
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.primary-button {
  background-color: var(--btn-primary-bg);
}

.secondary-button {
  background-color: var(--btn-secondary-bg);
}

.modal-buttons {
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: space-evenly;
}
