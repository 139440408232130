/* footer */
.footer {
  background-color: var(--home-primary-bg);
  color: var(--clr-light);
  padding: 0.8rem 0.5rem;
  text-align: center;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}
.footer-url {
  text-decoration: underline;
  color: var(--clr-light);
}
.footer-url:hover {
  opacity: 80%;
  text-decoration: underline;
  color: var(--clr-light);
}

