.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 180;
  height: 100vh;
  width: 100vw;
  background-color: var(--modal-bg);
  display: grid;
  place-items: center;
}
.modal-content {
  width: 45%;
  padding: 1rem;
  background-color: var(--clr-light);
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modal-heading {
  padding: 1rem 2rem;
  margin-top: 1rem;
  font-weight: bold;
}
.modal-warning {
  background-color: var(--clr-error-alert-bg);
  color: var(--clr-error-alert-text);
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  padding: 1rem 2rem;
  margin-block: 1rem;
}
.modal-buttons {
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  justify-content: space-evenly;
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.primary-button {
  background-color: var(--btn-primary-bg);
}

.secondary-button {
  background-color: var(--btn-secondary-bg);
}
.danger-button {
  background-color: var(--btn-danger-bg);
}
