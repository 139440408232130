.ql-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.ql-editor {
  flex-grow: 1;
  overflow: auto;
}

/* Card */
.card {
  background-color: var(--clr-light);
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-primary);
}

/* Buttons */
.button {
  padding: 0.5rem 3rem;
  color: var(--clr-light);
  cursor: pointer;
}

.button:hover {
  opacity: 90%;
  transition: opacity 0.1s ease-in;
}

.button:not([disabled]):not(.filter-dropdown):active {
  box-shadow: var(--box-shadow-button-active);
  transform: translateY(4px);
}

.button[disabled] {
  opacity: 50%;
  transition: opacity 0.3s ease-in;
}

.primary-button {
  background-color: var(--btn-primary-bg);
}

.invalid-message-field{
  border: 2px solid #ed2939 !important;
}
